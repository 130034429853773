import permitsEn from "pages/mall/permits/translations/en/common.json";
import permitsId from "pages/mall/permits/translations/id/common.json";
import loginEn from "pages/mall/login/translations/en/common.json";
import loginId from "pages/mall/login/translations/id/common.json";
import authEn from "pages/mall/auth/translations/en/common.json";
import authId from "pages/mall/auth/translations/id/common.json";

const intlConf = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      permits: permitsEn,
      login: loginEn,
      auth: authEn,
    },
    id: {
      permits: permitsId,
      login: loginId,
      auth: authId,
    },
  },
};

export default intlConf;
