import ApiService from "services/ApiService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import md5 from "md5";

const reqLogin = createAsyncThunk("auth/login", async (request) => {
  try {
    const http = await ApiService();
    const response = await http.post("/login", {
      email: request.email,
      password: md5(request.password),
      fcm_token_web: "aueo",
    });
    return response.data;
  } catch (err) {
    if (err?.response?.data) {
      throw err.response.data.message;
    } else {
      throw new Error("An error occurred");
    }
  }
});

const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ({ id, request }) => {
    try {
      const http = await ApiService();
      const response = await http.patch(`/user/${id}`, request);
      return response.data;
    } catch (err) {
      if (err?.response?.data) {
        throw err.response.data.message;
      } else {
        throw new Error("An error occurred");
      }
    }
  }
);

const resetAUser = createAsyncThunk("auth/resetAUser", async () => {
  return "";
});

export { reqLogin, updateUser, resetAUser };
