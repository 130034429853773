import { Component } from "react";
import { message } from "antd";
import { clearLocalStorage } from "components/utils";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { err: null };
    this.promiseRejectionHandler = this.promiseRejectionHandler.bind(this);
  }

  static getDerivedStateFromError(err) {
    return { err };
  }

  promiseRejectionHandler(event) {
    if (
      event.reason.response?.status === 401 ||
      event.reason.response?.statusText === "Unauthorized"
    ) {
      console.log("tes logout");
      message.warning(event.reason.response.data.message);
      clearLocalStorage();
    } else {
      message.danger("An Error Occurred");
    }
  }

  componentDidCatch(err, errInfo) {
    console.log("cek2 hhh");
    // console.log(err);
    // console.log(errInfo);
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
