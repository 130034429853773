import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: null,
  typeDate: "date",
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setTypeDate: (state, action) => {
      state.typeDate = action.payload;
    },
  },
});

export const { setDate, setTypeDate } = overviewSlice.actions;

export default overviewSlice.reducer;
