import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthServices from "pages/mall/auth/services";
import { Card, Form, Input, Button, Row, Col, Typography, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { css } from "emotion";
import logo from "assets/img/login-logo.png";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

function ForgotPass() {
  const { t } = useTranslation("auth");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const ref = useRef();

  const handleForgotPass = async (data) => {
    try {
      setIsLoading(true);
      await AuthServices.forgotPass(data); //still wait BE
      history.replace("/forgot-pass-success");
    } catch (err) {
      if (
        err?.response?.status === 404 &&
        err?.response?.data?.message === "Not Found"
      ) {
        const errMsg = t("Email not registered, try other");
        message.error(errMsg);
      } else {
        const errMsg = t("An error occurred");
        message.error(errMsg);
      }
      const { input } = ref.current;
      input.focus();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row
      justify="center"
      align="middle"
      className={css`
        height: calc(100vh) !important;
        overflow-y: overlay;
        background-color: #bac62a !important;
      `}
    >
      <Col
        xs={{ span: 22 }}
        sm={{ span: 12 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <Card>
          <div
            className={css`
              text-align: center;
            `}
          >
            <img
              src={logo}
              alt="logo-login"
              className={css`
                width: 50%;
                margin-bottom: 10px;
              `}
            />
          </div>
          <Title level={5}>{t("Reset Password")}</Title>
          <Form
            initialValues={{
              remember: true,
            }}
            onFinish={handleForgotPass}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t("Please input your valid Email"),
                },
              ]}
            >
              <Input
                ref={ref}
                size="large"
                prefix={<MailOutlined />}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={isLoading}
                block
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Send")}
              </Button>
            </Form.Item>
          </Form>
          <div className="text-center">
            {t("Already remember account")}?{" "}
            <Link to="/login">{t("Login Here")}!</Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default ForgotPass;
