import history from "routes/history";
import { createSlice, current } from "@reduxjs/toolkit";
import { reqLogin, updateUser, resetAUser } from "./authAction";
import { setMallRoutes } from "./makeMenu";

const initialState = {
  token: "",
  property_id: "",
  user_id: "",
  tenant: "",
  userData: {},
  mallRoutes: [],
  isLoading: false,
  error: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducer: {
    resetUserData: (state, action) => {
      state.token = "";
      state.property_id = "";
      state.user_id = "";
      state.tenant = "";
      state.userData = {};
      state.mallRoutes = [];
      state.isLoading = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reqLogin.fulfilled, (state, action) => {
      //auth handle v2
      // const { token, property, tenant, id } = action?.payload;
      state.token = action.payload.token;
      state.property_id = action.payload.property.id;
      state.tenant = action.payload.tenant;
      state.user_id = action.payload.id;

      const newModules = action.payload?.role?.modules?.map((item) => {
        if (item.id === 7) {
          let a = null;
          let b = null;
          let c = null;
          let d = null;
          let e = null;
          let f = null;
          let g = null;
          item.features.map((feature) => {
            switch (feature.id) {
              case 18:
                a = feature;
                break;
              case 19:
                b = feature;
                break;
              case 20:
                c = feature;
                break;
              case 21:
                d = feature;
                break;
              case 22:
                e = feature;
                break;
              case 23:
                f = feature;
                break;
              case 24:
                g = feature;
                break;
              default:
                console.log("");
            }
          });
          let arrFeature = [a, e, d, b, g, f, c].filter(
            (item) => item !== null
          );

          return { ...item, features: arrFeature };
        } else {
          return item;
        }
      });

      const dataLogin = {
        ...action?.payload,
        role: {
          ...action?.payload.role,
          modules: newModules,
        },
      };

      state.mallRoutes = setMallRoutes(dataLogin);
      Object.assign(state, { userData: dataLogin });

      //auth handle v1 (depreciated)
      const userData = dataLogin;
      localStorage.setItem("token", userData.token);
      localStorage.setItem("property_id", userData.property.id);
      localStorage.setItem("user_id", userData.id);
      localStorage.setItem("tenant", JSON.stringify(userData.tenant));
      localStorage.setItem("userData", JSON.stringify(userData));

      state.isLoading = false;
      // history.push("/buildingManagement/tenantMeterUtility");
      // history.push(newModules[0].features[0].path);
    });

    builder.addCase(reqLogin.pending, (state) => {
      state.isLoading = true;
      state.token = "";
      state.property_id = "";
      state.tenant = "";
      state.user_id = "";
      Object.assign(state, { userData: {} });
    });

    builder.addCase(reqLogin.rejected, (state, action) => {
      state.error = action?.error;
      state.isLoading = false;
      state.token = "";
      state.property_id = "";
      state.tenant = "";
      state.user_id = "";
      Object.assign(state, { userData: {} });
    });

    //update user
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.userData.name = action.payload.name;
      state.userData.email = action.payload.email;
      state.userData.phone = action.payload.phone;
      state.userData.address = action.payload.address;
      state.isLoading = false;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = action?.error;
      state.isLoading = false;
    });

    // logout
    builder.addCase(resetAUser.fulfilled, (state, _) => {
      state.token = "";
      state.property_id = "";
      state.user_id = "";
      state.tenant = "";
      state.userData = {};
      state.mallRoutes = [];
      state.isLoading = false;
      state.error = "";
    });
  },
});

export const { resetUserData } = authSlice.actions;
export default authSlice.reducer;
