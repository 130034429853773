import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Form, Input, Button, Row, Col, Dropdown, Space } from "antd";
import { MailOutlined, LockOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { css } from "emotion";
import { message } from "antd";
import logo from "assets/img/login-logo.png";
import { reqLogin } from "stores/auth/authAction";
import history from "routes/history";
import { useTranslation } from "react-i18next";
import enFlag from "assets/images/flags/en.png";
import idFlag from "assets/images/flags/id.png";

function Login() {
  const { t, i18n } = useTranslation("login");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (val) => {
    try {
      setIsLoading(true);
      const res = await dispatch(reqLogin(val)).unwrap();
      if (res?.meta?.requestStatus === "rejected") {
        throw res.error.message;
      }
      setIsLoading(false);
      history.push(res?.role?.modules[0]?.features[0]?.path);
      message.success("Login success");
    } catch (err) {
      message.error(err.message);
      setIsLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div onClick={() => i18n.changeLanguage("en")}>
          <img
            src={enFlag}
            className={css`
              width: 25px;
              margin-right: 10px;
            `}
            alt="flag_ed"
          />
          English
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => i18n.changeLanguage("id")}>
          <img
            src={idFlag}
            className={css`
              width: 25px;
              margin-right: 10px;
            `}
            alt="flag_id"
          />
          Bahasa Indonesia
        </div>
      ),
    },
  ];

  return (
    <Row
      justify="center"
      align="middle"
      className={css`
        height: calc(100vh) !important;
        overflow-y: overlay;
        background-color: #bac62a !important;
      `}
    >
      <Col
        xs={{ span: 22 }}
        sm={{ span: 12 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <Card>
          <div
            className={css`
              text-align: center;
            `}
          >
            <img
              src={logo}
              alt="logo-login"
              className={css`
                width: 50%;
                margin-bottom: 10px;
              `}
            />
          </div>
          <Form
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t("Please input your valid Email"),
                },
              ]}
            >
              <Input
                size="large"
                prefix={<MailOutlined />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Please input your Password!"),
                },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined />}
                type="password"
                placeholder={t("Password")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={isLoading}
                block
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Log in")}
              </Button>
            </Form.Item>
          </Form>
          <div className="text-center mb-3">
            <Link to="/forgot-pass">{t("Forgot Password")}?</Link>
          </div>
          <Row justify={"center"}>
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <span
                className={css`
                  cursor: pointer;
                `}
                onClick={(e) => e.preventDefault()}
              >
                {i18n.language === "en" ? (
                  <Space>
                    <img
                      src={enFlag}
                      className={css`
                        width: 25px;
                        margin-right: 10px;
                      `}
                      alt="flag_id"
                    />
                    English
                    <DownOutlined />
                  </Space>
                ) : (
                  <Space>
                    <img
                      src={idFlag}
                      className={css`
                        width: 25px;
                        margin-right: 10px;
                      `}
                      alt="flag_id"
                    />
                    Bahasa Indonesia
                    <DownOutlined />
                  </Space>
                )}
              </span>
            </Dropdown>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
