import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { clearLocalStorage } from "components/utils";
import { setDate } from "stores/overview/overviewSlice";
import useWatch from "helpers/useWatch";

const PrivateRoute = ({ name, path, component: Component, ...rest }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("userData"));
  const { role } = user ? user : [];
  const { modules } = role ? role : [];

  const arrRoute = [
    "/profile",
    "/masterData/asset/create",
    "/masterData/asset/update",
    "/buildingManagement/preventiveMaintenance/summary-view",
    "/overview/wo",
    "/overview/sm",
    "/overview/cr",
    "/overview/permits",
  ];

  const addRule = (route) => {
    if (arrRoute.includes(route) && token) {
      return true;
    } else {
      return false;
    }
  };

  let allowAccess = {
    name: "",
    read: addRule(location.pathname),
    create: false,
    delete: false,
    update: false,
    data: [],
  };

  if (token && user) {
    modules.map((value) => {
      value.features.map((row) => {
        // console.log(row, "dasdask");
        // console.log(path, "dasdask");
        if (path.toLowerCase() === row.path.toLowerCase()) {
          if (row.read) {
            allowAccess = {
              name: row.name,
              read: row.read,
              create: row.create,
              delete: row.delete,
              update: row.update,
              patch: row.patch,
            };
          }
        }
        return allowAccess;
      });
      return allowAccess;
    });
  }

  useLayoutEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  const dispatch = useDispatch();
  useWatch(() => {
    if (!location?.pathname?.includes("overview")) {
      dispatch(setDate(null));
    }
  }, [location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) =>
        allowAccess.read ? (
          <Component params={allowAccess} {...props} />
        ) : (
          clearLocalStorage()
        )
      }
    />
  );
};

export default PrivateRoute;
