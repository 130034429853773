import React, { Suspense } from "react";
import history from "routes/history";
import loader from "assets/img/loader.svg";
import { notifUtils } from "components/notifUtil";

const WaitingComponent = (Component) => {
  return (props) => (
    <Suspense
      fallback={
        <div className="preloader">
          <div className="loading text-center">
            <img alt="img_wait_loader" src={loader} width="80" />
            <p>Please wait...</p>
          </div>
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
const clearLocalStorage = () => {
  localStorage.clear();
  history.replace("/login");
};
const textLimit = (text, maxChar = 0) => {
  return text.slice(0, maxChar) + (text.length > maxChar ? "..." : "");
};
const arrayFilter = (arr, key, value) => {
  var result =
    arr.length > 0 &&
    arr.filter((val) => {
      return val[key] === value;
    });
  return result ? result[0] : null;
};
const handleError = (error) => {
  const status = error.response ? error.response.status : "";
  if (status) {
    if (status === 401 || status === 402 || status === 403) {
      clearLocalStorage();
    } else if (status === 409) {
      notifUtils(`Email ${error.response.data.message} on Database`, "error");
    } else {
      notifUtils("working in background", "warning");
    }
  }
};
export {
  WaitingComponent,
  clearLocalStorage,
  textLimit,
  arrayFilter,
  handleError,
};
