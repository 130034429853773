const changeNametoPath = (name) => {
  if (name === "Analytic") {
    return "/analytic/visualAnalytic";
  } else if (name === "News") {
    return "/news/internalNews";
  } else if (name === "BM Department") {
    return "/bmDepartment/tenantMeterUtility";
  } else if (name === "Operation Department") {
    return "/operationDepartment/securitySchedulingActivity";
  } else if (name === "TR Department") {
    return "/trDepartment/tenantIssueComplaint";
  } else if (name === "Master Data") {
    return "/masterData/asset";
  } else if (name === "Category") {
    return "/category/tenant";
  } else if (name === "Settings") {
    return "/settings/property";
  }
  return "/dashboard";
};

const changeNametoIcon = (name) => {
  if (name === "Analytics") {
    return "rocket";
  } else if (name === "News") {
    return "feed";
  } else if (name === "Building Management") {
    return "notebook";
  } else if (name === "Operational") {
    return "organization";
  } else if (name === "Tenant & Internal") {
    return "ban";
  } else if (name === "Master Data") {
    return "eyeglass";
  } else if (name === "Category") {
    return "puzzle";
  } else if (name === "Settings") {
    return "settings";
  } else if (name === "Reporting BM") {
    return "notebook";
  }
  return "speedometer";
};

export const setMallRoutes = (userData) => {
  let arrayMenu = [
    // {
    //   path: "/dashboard",
    //   name: "Dashboards",
    //   icon: "speedometer",
    //   type: "direct",
    // },
  ];
  for (var i = 0; i < userData.role.modules.length; i++) {
    let child = [];
    for (var j = 0; j < userData.role.modules[i].features.length; j++) {
      let obj2 = {
        path: userData.role.modules[i].features[j].path,
        name:
          userData.role.modules[i].features[j].name === "Preventive"
            ? "Maintenance"
            : userData.role.modules[i].features[j].name ===
              "Tenant Loading-Unloading Request"
            ? "Loading - Unloading"
            : userData.role.modules[i].features[j].name,
        type: "direct",
      };

      //fungsi hide sementara
      if (
        userData.role.modules[i].features[j].name == "Rate Card" ||
        // userData.role.modules[i].features[j].name ==
        //   "Tenant Loading-Unloading Request" ||
        //userData.role.modules[i].features[j].name == 'Internal Work Order' ||
        userData.role.modules[i].features[j].name == "Loading-Unloading"
        // || userData.role.modules[i].features[j].name == 'Work Order'
      ) {
      } else {
        child.push(obj2);
      }
    }
    let obj = {
      // path: changeNametoPath(userData.role.modules[i].name),
      path:
        userData.role.modules[i].features.length === 0
          ? changeNametoPath(userData.role.modules[i].name)
          : userData.role.modules[i].features[0].path,
      name: userData.role.modules[i].name,
      icon: userData.role.modules[i].icon
        ? userData.role.modules[i].icon
        : changeNametoIcon(userData.role.modules[i].name),
      type: "dropdown", // dropdown, direct
      parentid: "multipurpose",
      child,
    };
    //fungsi hide sementara
    if (
      userData.role.modules[i].name == "Operational" ||
      userData.role.modules[i].name == "Analytics"
    ) {
    } else {
      arrayMenu.push(obj);
    }
  }
  localStorage.setItem("mallRoutes", JSON.stringify(arrayMenu));
  return arrayMenu;
};
