import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./stores";
import AppRouter from "router";
import ErrorBoundary from "components/ErrorBoundary";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css"; //to active sidebar & header
/*import 'font-awesome/css/font-awesome.min.css';*/
import "assets/scss/zest-admin.css"; //to active sidebar & header
import "assets/scss/mall-style.css";
import "assets/fonts/simple-line-icons.css"; //icon sidebar && header
// import "assets/awesome-fonts/css/font-awesome.css";
import "assets/awesome-fonts/css/font-awesome.min.css"; //icon for custom button dashboard zest-admin
// import "./styles/load-styles.scss";
import SimpleReactLightbox from "simple-react-lightbox";
import "./styles/antd/custom-theme.css";
import "antd-css-utilities/utility.min.css";
import { AssetItemProvider } from "pages/mall/masterData/asset/context";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import intlConf from "./internationalize/conf";

i18next.init(intlConf);

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <SimpleReactLightbox>
            <ErrorBoundary>
              <AssetItemProvider>
                <AppRouter />
              </AssetItemProvider>
            </ErrorBoundary>
          </SimpleReactLightbox>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </I18nextProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
