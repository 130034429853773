import React, { useState, createContext } from "react";

const AssetItemContext = createContext({
  data: [],
  isLoading: false,
  currentPage: 1,
  pageSize: 10,
  total: 0,
  search: "",
  optionProperties: [],
  selectedProperty: null,
  currentData: {},
  categoryItem: null,
  setData: () => {},
  setIsLoading: () => {},
  setCurrentPage: () => {},
  setPageSize: () => {},
  setTotal: () => {},
  setSearch: () => {},
  setOptionProperties: () => {},
  setSelectedProperty: () => {},
  setCurrentData: () => {},
  setCategoryItem: () => {},
});

const AssetItemProvider = ({ children }) => {
  const setData = (data) => {
    setState((val) => ({ ...val, data }));
  };

  const setIsLoading = (isLoading) => {
    setState((val) => ({ ...val, isLoading }));
  };

  const setCurrentPage = (currentPage) => {
    setState((val) => ({ ...val, currentPage }));
  };

  const setPageSize = (pageSize) => {
    setState((val) => ({ ...val, pageSize }));
  };

  const setTotal = (total) => {
    setState((val) => ({ ...val, total }));
  };

  const setSearch = (search) => {
    setState((val) => ({ ...val, search }));
  };

  const setOptionProperties = (optionProperties) => {
    setState((val) => ({ ...val, optionProperties }));
  };

  const setSelectedProperty = (selectedProperty) => {
    setState((val) => ({ ...val, selectedProperty }));
  };
  const setCurrentData = (currentData) => {
    setState((val) => ({ ...val, currentData }));
  };

  const setCategoryItem = (categoryItem) => {
    setState((val) => ({ ...val, categoryItem }));
  };

  const initialState = {
    data: [],
    isLoading: false,
    currentPage: 1,
    pageSize: 10,
    total: 0,
    search: "",
    optionProperties: [],
    selectedProperty: null,
    currentData: {},
    categoryItem: null,
    setData,
    setIsLoading,
    setCurrentPage,
    setPageSize,
    setTotal,
    setSearch,
    setOptionProperties,
    setSelectedProperty,
    setCurrentData,
    setCategoryItem,
  };

  const [state, setState] = useState(initialState);

  return (
    <AssetItemContext.Provider value={state}>
      {children}
    </AssetItemContext.Provider>
  );
};

export { AssetItemContext, AssetItemProvider };
