import Axios from "axios";

const defaultHeader = async () => {
  const token = localStorage.getItem("token");
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : `Bearer 12345`,
    "X-App-Group": "1",
    "X-App-Brand": "1",
    "X-App-Source": "web",
    "X-App-Version": "0.0.1",
    "X-App-Os": "Chrome",
    "X-App-Language": "en",
  };
};

const ApiService = async () => {
  const header = await defaultHeader();

  const instance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 600000,
    headers: header,
  });

  instance.interceptors.request.use(
    function (config) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["X-Timezone"] = timezone;
      return config;
    },
    function (error) {
      // Do something with request error
      // console.log({ error });
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return instance;
};

export default ApiService;
