import React, { lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "routes/history";
import PrivateRoutes from "components/privateRoute";
import { WaitingComponent, clearLocalStorage } from "components/utils";

import login from "pages/mall/login";
import ForgotPass from "pages/mall/auth/ForgotPass";
import ForgotPassSuccess from "pages/mall/auth/ForgotPassSuccess";
import notFound from "pages/mall/notFound";

// const dashboardOverview = WaitingComponent(
//   lazy(() => import("pages/mall/dashboard/overview"))
// );

// const dashboardWorkSchedule = WaitingComponent(
//   lazy(() => import("pages/mall/dashboard/work-schedule"))
// );
//Dashboard

const dashboardOverview = WaitingComponent(
  lazy(() => import("pages/mall/dashboard/all"))
);

const dashboardOverviewCr = WaitingComponent(
  lazy(() => import("pages/mall/dashboard/complaint-request"))
);

const dashboardWo = WaitingComponent(
  lazy(() => import("pages/mall/dashboard/work-order"))
);

const dashboardSm = WaitingComponent(
  lazy(() => import("pages/mall/dashboard/scheduled-maintenance"))
);

const dashboarOverviewPermits = WaitingComponent(
  lazy(() => import("pages/mall/dashboard/permits"))
);

const DashboardStaffList = WaitingComponent(
  lazy(() => import("pages/mall/dashboard/staff"))
);

//Recap
const OverviewRecap = WaitingComponent(
  lazy(() => import("pages/mall/recap/overview"))
);

const SmRecap = WaitingComponent(
  lazy(() => import("pages/mall/recap/scheduled-maintenance"))
);

const WoRecap = WaitingComponent(
  lazy(() => import("pages/mall/recap/workorder"))
);

const AssetRecap = WaitingComponent(
  lazy(() => import("pages/mall/recap/asset"))
);

// Analytic
const analyticsVisual = WaitingComponent(
  lazy(() => import("pages/mall/analytic/visualAnalytic"))
);
const analyticsReporting = WaitingComponent(
  lazy(() => import("pages/mall/analytic/reporting"))
);

// News
const promotionInternalTenant = WaitingComponent(
  lazy(() => import("pages/mall/news"))
);

// Building Management
const tenantMeterUtility = WaitingComponent(
  lazy(() => import("pages/mall/buildingManagement/tenantMeterUtility"))
);
const preventiveMaintenance = WaitingComponent(
  lazy(() => import("pages/mall/buildingManagement/preventiveMaintenance"))
);

const preventiveMaintenanceSummary = WaitingComponent(
  lazy(() =>
    import(
      "pages/mall/buildingManagement/preventiveMaintenance/lists/summary-view"
    )
  )
);

// Operational
const securitySchedulingActivity = WaitingComponent(
  lazy(() => import("pages/mall/operational/securitySchedulingActivity"))
);
const parkingScheduling = WaitingComponent(
  lazy(() => import("pages/mall/operational/parkingScheduling"))
);
const housekeepingScheduling = WaitingComponent(
  lazy(() => import("pages/mall/operational/housekeepingScheduling"))
);

// Tenant & Internal
const ComplaintRequest = WaitingComponent(
  lazy(() => import("pages/mall/tenantInternal/ComplaintRequest"))
);
const internalWorkOrder = WaitingComponent(
  lazy(() => import("pages/mall/tenantInternal/internalWorkOrder"))
);
// const tenantLoadingUnloadingRequest = WaitingComponent(
//   lazy(() => import("pages/mall/tenantInternal/tenantLoadingUnloadingRequest"))
// );
const externalLoadingUnloading = WaitingComponent(
  lazy(() => import("pages/mall/permits/unloading"))
);
const internalLoadingUnloading = WaitingComponent(
  lazy(() => import("pages/mall/permits/loading"))
);
const dashboardSika = WaitingComponent(
  lazy(() => import("pages/mall/permits/sika"))
);

const rateCard = WaitingComponent(
  lazy(() => import("pages/mall/tenantInternal/rateCard"))
);

// Master data
const masterDataBasicPriceUtilities = WaitingComponent(
  lazy(() => import("pages/mall/masterData/basicPriceUtilities"))
);
const preventiveTemplates = WaitingComponent(
  lazy(() => import("pages/mall/masterData/preventiveTemplates"))
);
const rateCardProduct = WaitingComponent(
  lazy(() => import("pages/mall/masterData/rateCardProduct"))
);
const masterDataAsset = WaitingComponent(
  lazy(() => import("pages/mall/masterData/asset"))
);
const masterDataAssetCreate = WaitingComponent(
  lazy(() => import("pages/mall/masterData/asset/component/Sections/Create"))
);
const masterDataAssetUpdate = WaitingComponent(
  lazy(() => import("pages/mall/masterData/asset/component/Sections/Update"))
);

const masterDataAssetDetail = WaitingComponent(
  lazy(() => import("pages/mall/masterData/asset/component/Sections/Detail"))
);

const comreqTemplate = WaitingComponent(
  lazy(() => import("pages/mall/masterData/comreq"))
);

// Category
//const category = WaitingComponent(lazy(() => import("pages/mall/category")));
const tenant = WaitingComponent(
  lazy(() => import("pages/mall/category/tenant"))
);
const asset = WaitingComponent(lazy(() => import("pages/mall/category/asset")));
const complaintRequest = WaitingComponent(
  lazy(() => import("pages/mall/category/complaintRequest"))
);
const rateCardCategory = WaitingComponent(
  lazy(() => import("pages/mall/category/rateCard"))
);
const workOrder = WaitingComponent(
  lazy(() => import("pages/mall/category/workOrder"))
);
const tenantComplaint = WaitingComponent(
  lazy(() => import("pages/mall/category/tenantComplaint"))
);
const loadingUnloading = WaitingComponent(
  lazy(() => import("pages/mall/category/loadingUnloading"))
);
const categoryPreventive = WaitingComponent(
  lazy(() => import("pages/mall/category/preventive"))
);

// Setting
const settingProperty = WaitingComponent(
  lazy(() => import("pages/mall/settings/property"))
);
const settingTenant = WaitingComponent(
  lazy(() => import("pages/mall/settings/tenant"))
);
const settingFloor = WaitingComponent(
  lazy(() => import("pages/mall/settings/floor"))
);
const settingUnit = WaitingComponent(
  lazy(() => import("pages/mall/settings/unit"))
);
const settingRole = WaitingComponent(
  lazy(() => import("pages/mall/settings/role"))
);
const settingDept = WaitingComponent(
  lazy(() => import("pages/mall/settings/dept"))
);
const settingUserCsn = WaitingComponent(
  lazy(() => import("pages/mall/settings/user/csn"))
);
const settingUserProperty = WaitingComponent(
  lazy(() => import("pages/mall/settings/user/property"))
);
const settingUserTenant = WaitingComponent(
  lazy(() => import("pages/mall/settings/user/tenant"))
);

const settingLoadingUnloading = WaitingComponent(
  lazy(() => import("pages/mall/settings/loading-unloading"))
);

const settingStaffAccess = WaitingComponent(
  lazy(() => import("pages/mall/settings/user/access"))
);

const Profile = WaitingComponent(lazy(() => import("pages/mall/profile")));
const IconSettings = WaitingComponent(
  lazy(() => import("pages/mall/iconSettings"))
);

function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact name="Login" path="/login" component={login} />
        <Route
          exact
          name="ForgotPass"
          path="/forgot-pass"
          component={ForgotPass}
        />
        <Route
          exact
          name="ForgotPassSuccess"
          path="/forgot-pass-success"
          component={ForgotPassSuccess}
        />
        <Route path="/" name="Root" exact render={clearLocalStorage} />
        {/* Parent routs */}
        {/* <Route exact name="Dashboard" path="/dashboard" component={dashboard} /> */}

        <Route
          exact
          name="DashboardOverview"
          path="/overview"
          component={dashboardOverview}
        />

        <PrivateRoutes
          exact
          name="DashboardOverviewCr"
          path="/overview/cr"
          component={dashboardOverviewCr}
        />

        <PrivateRoutes
          exact
          name="DashboardOverviewWo"
          path="/overview/wo"
          component={dashboardWo}
        />

        <PrivateRoutes
          exact
          name="DashboardOverviewSM"
          path="/overview/sm"
          component={dashboardSm}
        />

        <PrivateRoutes
          exact
          name="dashboarOverviewPermits"
          path="/overview/permits"
          component={dashboarOverviewPermits}
        />

        {/* Recap */}
        <PrivateRoutes
          exact
          name="RecapSm"
          path="/recap/pm"
          component={SmRecap}
        />
        <PrivateRoutes
          exact
          name="RecapWo"
          path="/recap/wo"
          component={WoRecap}
        />

        <PrivateRoutes
          exact
          name="RecapAsset"
          path="/recap/asset"
          component={AssetRecap}
        />

        <PrivateRoutes
          exact
          name="RecapOverview"
          path="/recap/overview"
          component={OverviewRecap}
        />

        {/* <PrivateRoutes
          exact
          name="DashboardWorkSchedule"
          path="/work-schedule"
          component={dashboardWorkSchedule}
        /> */}

        {/* Analytic */}
        <PrivateRoutes
          exact
          name="Analytic"
          path="/analytic/visualAnalytic"
          component={analyticsVisual}
        />
        <PrivateRoutes
          exact
          name="Analytic"
          path="/analytic/reporting"
          component={analyticsReporting}
        />
        {/* News */}
        <PrivateRoutes
          exact
          name="Promotion"
          path="/news/internalNews"
          component={promotionInternalTenant}
        />
        <PrivateRoutes
          exact
          name="Promotion"
          path="/news/tenantNews"
          component={promotionInternalTenant}
        />
        {/* Building Management */}
        <PrivateRoutes
          exact
          name="TenantMeterUtility"
          path="/buildingManagement/tenantMeterUtility"
          component={tenantMeterUtility}
        />
        <PrivateRoutes
          exact
          name="PreventiveMaintenance"
          path="/buildingManagement/preventiveMaintenance"
          component={preventiveMaintenance}
        />

        <PrivateRoutes
          exact
          name="preventiveMaintenanceSummary"
          path="/buildingManagement/preventiveMaintenance/summary-view"
          component={preventiveMaintenanceSummary}
        />

        {/* Operational */}
        <PrivateRoutes
          exact
          name="SecuritySchedulingActivity"
          path="/operational/securitySchedulingActivity"
          component={securitySchedulingActivity}
        />
        <PrivateRoutes
          exact
          name="ParkingScheduling"
          path="/operational/parkingScheduling"
          component={parkingScheduling}
        />
        <PrivateRoutes
          exact
          name="HousekeepingScheduling"
          path="/operational/housekeepingScheduling"
          component={housekeepingScheduling}
        />
        {/* Tenant & Internal */}
        <PrivateRoutes
          exact
          name="ComplaintRequest"
          path="/tenantInternal/ComplaintRequest"
          component={ComplaintRequest}
        />
        <PrivateRoutes
          exact
          name="InternalWorkOrder"
          path="/tenantInternal/internalWorkOrder"
          component={internalWorkOrder}
        />
        {/* Tenant & Internal */}
        <PrivateRoutes
          exact
          name="externalLoadingUnloading"
          path="/loadingUnloading/external"
          component={externalLoadingUnloading}
        />
        <PrivateRoutes
          exact
          name="internalLoadingUnloading"
          path="/loadingUnloading/internal"
          component={internalLoadingUnloading}
        />

        <PrivateRoutes
          exact
          name="dashboardSika"
          path="/loadingUnloading/sika"
          component={dashboardSika}
        />

        <PrivateRoutes
          exact
          name="dashboardStaffList"
          path="/staff"
          component={DashboardStaffList}
        />

        <PrivateRoutes
          exact
          name="Rate Card"
          path="/tenantInternal/rateCard"
          component={rateCard}
        />

        {/* Master Data */}
        <PrivateRoutes
          exact
          name="BasicPriceUtilities"
          path="/masterData/basicPriceUtilities"
          component={masterDataBasicPriceUtilities}
        />
        <PrivateRoutes
          exact
          name="MasterDataAsset"
          path="/masterData/asset"
          component={masterDataAsset}
        />
        <PrivateRoutes
          exact
          name="MasterDataAssetCreate"
          path="/masterData/asset/create"
          component={masterDataAssetCreate}
        />

        <Route
          exact
          name="MasterDataAssetUpdate"
          path="/masterData/asset/update/:id"
          component={masterDataAssetUpdate}
        />

        <Route
          exact
          name="MasterDataAsset"
          path="/masterData/asset/detail/:id"
          component={masterDataAssetDetail}
        />
        <PrivateRoutes
          exact
          name="Preventive Templates"
          path="/masterData/preventiveTemplates"
          component={preventiveTemplates}
        />
        <PrivateRoutes
          exact
          name="Rate Card Product"
          path="/masterData/rateCardProduct"
          component={rateCardProduct}
        />

        <PrivateRoutes
          exact
          name="Comreq Template"
          path="/masterData/comreq"
          component={comreqTemplate}
        />

        {/* Category */}

        <PrivateRoutes
          exact
          name="Tenant"
          path="/category/tenant"
          component={tenant}
        />
        <PrivateRoutes
          exact
          name="Asset"
          path="/category/asset"
          component={asset}
        />
        <PrivateRoutes
          exact
          name="complaintRequest"
          path="/category/complaintRequest"
          component={complaintRequest}
        />
        <PrivateRoutes
          exact
          name="RateCardCategory"
          path="/category/rateCard"
          component={rateCardCategory}
        />
        <PrivateRoutes
          exact
          name="WorkOrder"
          path="/category/workOrder"
          component={workOrder}
        />
        <PrivateRoutes
          exact
          name="TenantComplaint"
          path="/category/tenantComplaint"
          component={tenantComplaint}
        />
        <PrivateRoutes
          exact
          name="LoadingUnloading"
          path="/category/loadingUnloading"
          component={loadingUnloading}
        />
        <PrivateRoutes
          exact
          name="Preventive"
          path="/category/preventive"
          component={categoryPreventive}
        />

        {/* Settings */}
        <PrivateRoutes
          exact
          name="Propert"
          path="/settings/property"
          component={settingProperty}
        />
        <PrivateRoutes
          exact
          name="Tenant"
          path="/settings/tenant"
          component={settingTenant}
        />
        <PrivateRoutes
          exact
          name="Floor"
          path="/settings/floor"
          component={settingFloor}
        />
        <PrivateRoutes
          exact
          name="Role"
          path="/settings/role"
          component={settingRole}
        />
        <PrivateRoutes
          exact
          name="Unit"
          path="/settings/unit"
          component={settingUnit}
        />
        <PrivateRoutes
          exact
          name="Dept"
          path="/settings/dept"
          component={settingDept}
        />
        <PrivateRoutes
          exact
          name="User Csn"
          path="/settings/userCsn"
          component={settingUserCsn}
        />
        <PrivateRoutes
          exact
          name="User Property"
          path="/settings/userProperty"
          component={settingUserProperty}
        />
        <PrivateRoutes
          exact
          name="User Tenant"
          path="/settings/userTenant"
          component={settingUserTenant}
        />

        <PrivateRoutes
          exact
          name="Settig Loading Unloading Holiday"
          path="/setting/loading-unloadingHoliday"
          component={settingLoadingUnloading}
        />
        <PrivateRoutes
          exact
          name="Settig Staff Acces"
          path="/setting/staff/access"
          component={settingStaffAccess}
        />

        <PrivateRoutes
          exact
          name="Profile"
          path="/profile"
          component={Profile}
        />
        <Route
          exact
          name="Icon Settings"
          path="/icon-settings"
          component={IconSettings}
        />
        <Route path="*" component={notFound} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
