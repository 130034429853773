import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Typography } from "antd";
import { css } from "emotion";
import logo from "assets/img/login-logo.png";
import forgotPass from "assets/images/auth/success-reset.svg";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

function ForgotPassSuccess() {
  const { t, i18n } = useTranslation("auth");

  return (
    <Row
      justify="center"
      align="middle"
      className={css`
        height: calc(100vh) !important;
        overflow-y: overlay;
        background-color: #bac62a !important;
      `}
    >
      <Col
        xs={{ span: 22 }}
        sm={{ span: 12 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
      >
        <Card>
          <div
            className={css`
              text-align: center;
            `}
          >
            <img
              src={logo}
              alt="logo-login"
              className={css`
                width: 50%;
                margin-bottom: 10px;
              `}
            />
          </div>
          {i18n.language === "en" ? (
            <Title level={5}>
              Reset password{" "}
              <span
                className={css`
                  color: #6fbd71 !important;
                `}
              >
                success
              </span>
              , new password will sent to your email, please check your email
              now!
            </Title>
          ) : (
            <Title level={5}>
              Reset Kata sandi{" "}
              <span
                className={css`
                  color: #6fbd71 !important;
                `}
              >
                berhasil
              </span>
              , kata sandi baru akan dikirim ke email anda, cek email anda
              sekarang!
            </Title>
          )}
          <div className="text-center my-3">
            <img
              src={forgotPass}
              alt="done"
              className={css`
                width: 50%;
              `}
            />
          </div>
          <div className="text-center">
            {t("Already remember account")}?{" "}
            <Link to="/login">{t("Login Here")}!</Link>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default ForgotPassSuccess;
